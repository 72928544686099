import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import nftContractAbi from "../Abis/escrow-abi.json";
import CryptoAddressConstants from "../constants/CryptoAddress.json";
import Layout from "./Layout";
import NavBar from "./NavBar";
import {
  convertTimestampToDateTime,
  convertProductStateToStr,
  convertCryptoStateToStr,
  findCurrencyType,
  formatShortAddress,
} from "../utils/utils";

export default function Transactions() {
  const { chainId, active, account } = useWeb3React();
  const validNetwork =
    chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;
  const [isWalletIntalled, setIsWalletInstalled] = useState(false);
  const [productTrades, setProductTrades] = useState([]);
  const [serviceTrades, setServiceTrades] = useState([]);
  const [cryptoTrades, setCryptoTrades] = useState([]);

  let myProvider, EscrowContract;
  if (window.ethereum) {
    myProvider = new ethers.providers.Web3Provider(window.ethereum);
    EscrowContract = new ethers.Contract(
      process.env.REACT_APP_ESCROW_ADDRESS,
      nftContractAbi,
      myProvider.getSigner()
    );
  }
  useEffect(() => {
    if (window.ethereum) {
      setIsWalletInstalled(true);
    }
  }, []);

  useEffect(() => {
    if (validNetwork && active && window.ethereum) {
      const myProvider = new ethers.providers.Web3Provider(window.ethereum);
      EscrowContract = new ethers.Contract(
        process.env.REACT_APP_ESCROW_ADDRESS,
        nftContractAbi,
        myProvider.getSigner()
      );

      updateProductList().catch(console.error);
      updateServiceList().catch(console.error);
      updateCryptoList().catch(console.error);
    }
  }, [validNetwork, active]);

  const updateProductList = async () => {
    let newArr = [];
    const productTradeCount = await EscrowContract.currentProductTradeId();
    for (let i = 1; i <= productTradeCount; i++) {
      const productTrade = await EscrowContract.productTrades(i);

      newArr.push({
        productId: i,
        createTime: convertTimestampToDateTime(productTrade.createTime),
        buyer: formatShortAddress(productTrade.buyer),
        seller: formatShortAddress(productTrade.seller),
        price: ethers.utils.formatEther(productTrade.price.toString()),
        currentState: convertProductStateToStr(productTrade.currentState),
      });
    }
    setProductTrades(newArr);
  };

  const updateServiceList = async () => {
    let newArr = [];
    const serviceTradeCount = await EscrowContract.currentServiceTradeId();
    for (let i = 1; i <= serviceTradeCount; i++) {
      const serviceTrade = await EscrowContract.serviceTrades(i);
      newArr.push({
        serviceId: i,
        createTime: convertTimestampToDateTime(serviceTrade.createTime),
        buyer: formatShortAddress(serviceTrade.buyer),
        seller: formatShortAddress(serviceTrade.seller),
        price: ethers.utils.formatEther(serviceTrade.price.toString()),
        currentState: convertProductStateToStr(serviceTrade.currentState),
      });
    }
    setServiceTrades(newArr);
  };

  const updateCryptoList = async () => {
    let newArr = [];
    const cryptoTradeCount = await EscrowContract.currentCryptoTradeId();
    for (let i = 1; i <= cryptoTradeCount; i++) {
      const cryptoTrade = await EscrowContract.cryptoTrades(i);

      newArr.push({
        cryptoId: i,
        createTime: convertTimestampToDateTime(cryptoTrade.createTime),
        buyer:
          cryptoTrade.buyer.search(/0x[0]{40}/) !== -1
            ? "No Buyer"
            : formatShortAddress(cryptoTrade.buyer),
        seller: formatShortAddress(cryptoTrade.seller),
        currencyType: findCurrencyType(
          CryptoAddressConstants,
          cryptoTrade.currency
        ),
        amount:
          parseFloat(cryptoTrade.amount.toString()) /
          Math.pow(10, parseInt(cryptoTrade.decimals.toString())),
        price: ethers.utils.formatEther(cryptoTrade.price.toString()),
        currentState: convertCryptoStateToStr(cryptoTrade.completed),
      });
    }
    setCryptoTrades(newArr);
  };

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={8}
        className="margin-top-50"
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 256,
              height: 128,
            },
          }}
        >
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/bitcoin.svg"
                alt="Total Volume Traded"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Volume Traded</div>
                <div className="dashboard-overview-highlight">
                  3,000,000 USD
                </div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/woman.png"
                alt="Total Participants"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Participants</div>
                <div className="dashboard-overview-highlight">5,000</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/rating-star.jpg"
                alt="Success Rating"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Success Rating</div>
                <div className="dashboard-overview-highlight">96.5%</div>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">
          Recent Activity on Product Trading
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Date/Time
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  productTrades.length > 0 ? (
                    productTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Product
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          BNB
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={8}
                      >
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">
          Recent Activity on Service Trading
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Date/Time
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  serviceTrades.length > 0 ? (
                    serviceTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Service
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          BNB
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={8}
                      >
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">
          Recent Activity on Crypto Trading
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Date/Time
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Price
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  cryptoTrades.length > 0 ? (
                    cryptoTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Crypto
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currencyType}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.amount}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={9}
                      >
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={9}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={9}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Layout>
  );
}
