import { Web3ReactProvider } from "@web3-react/core";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Web3 from "web3";
import "./App.css";

import Transactions from "./components/Transactions";
import Appeals from "./components/Appeals";
import Admins from "./components/Admins";
import Others from "./components/Others";
import Chat from "./components/Chat";

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Navigate replace to="/transactions" />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/appeals" element={<Appeals />} />
            <Route path="/admins" element={<Admins />} />
            <Route path="/others" element={<Others />} />
            <Route path="/chat" element={<Chat />}>
              <Route path=":chatId" element={<Chat />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </Web3ReactProvider>
  );
}

export default App;
