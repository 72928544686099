import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import nftContractAbi from "../Abis/escrow-abi.json";
import Layout from "./Layout";
import NavBar from "./NavBar";
import { getHumanReadableMsg } from "../utils/utils";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Others() {
  const { chainId, active } = useWeb3React();
  const validNetwork =
    chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;
  const [isWalletIntalled, setIsWalletInstalled] = useState(false);
  const [currentFee, setCurrentFee] = useState("");
  const [newFee, setNewFee] = useState("");
  const [bannedAddress, setBannedAddress] = useState("");
  const [feeModalOpen, setFeeModalOpen] = useState(false);

  const handleFeeModalOpen = () => setFeeModalOpen(true);
  const handleFeeModalClose = () => setFeeModalOpen(false);

  let myProvider, EscrowContract;
  if (window.ethereum) {
    myProvider = new ethers.providers.Web3Provider(window.ethereum);
    EscrowContract = new ethers.Contract(
      process.env.REACT_APP_ESCROW_ADDRESS,
      nftContractAbi,
      myProvider.getSigner()
    );
  }
  useEffect(() => {
    if (window.ethereum) {
      setIsWalletInstalled(true);
    }
  }, []);

  useEffect(() => {
    if (validNetwork && active && window.ethereum) {
      const myProvider = new ethers.providers.Web3Provider(window.ethereum);
      EscrowContract = new ethers.Contract(
        process.env.REACT_APP_ESCROW_ADDRESS,
        nftContractAbi,
        myProvider.getSigner()
      );
      updateCurrentFee();
    }
  }, [validNetwork, active]);

  const handleNewFeeChange = (event) => {
    setNewFee(event.target.value);
  };

  const handleBannedAddressChange = (event) => {
    setBannedAddress(event.target.value);
  };

  const updateCurrentFee = async () => {
    const tax = await EscrowContract.totalTax();
    setCurrentFee(tax.toString());
  };

  const handleChangeFee = async () => {
    const isValid = validateInputs(1);
    if (!isValid) return;

    await EscrowContract.setTax(newFee)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            updateCurrentFee();

            return true;
          },
          (error) => {
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
          console.log("========", error);
        }
      });
  };

  const handleBannedAddressList = async (type) => {
    const isValid = validateInputs(2);
    if (!isValid) return;

    console.log(bannedAddress, type);
    await EscrowContract.manageBannedAddress(bannedAddress, type)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
          console.log("========", error);
        }
      });
  };

  const validateInputs = (type) => {
    let isValid = true;

    if (type === 1) {
      if (isNaN(newFee)) {
        isValid = false;
        toast.error("Only numbers are accepted");
        return isValid;
      }
    } else if (type === 2) {
      if (!ethers.utils.isAddress(bannedAddress)) {
        isValid = false;
        toast.error("The wallet address is not valid");
        return isValid;
      }
    }

    return isValid;
  };

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={8}
        className="margin-top-50"
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 256,
              height: 128,
            },
          }}
        >
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/bitcoin.svg"
                alt="Total Volume Traded"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Volume Traded</div>
                <div className="dashboard-overview-highlight">
                  3,000,000 USD
                </div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/woman.png"
                alt="Total Participants"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Participants</div>
                <div className="dashboard-overview-highlight">5,000</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/rating-star.jpg"
                alt="Success Rating"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Success Rating</div>
                <div className="dashboard-overview-highlight">96.5%</div>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">
          Manage the transaction fees
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-white font-bold"
                    >
                      Current Txn Fee
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-white font-bold"
                    >
                      {currentFee}
                    </TableCell>
                    <TableCell align="right" className="text-white">
                      <Button
                        variant="outlined"
                        className="text-white font-bold"
                        style={{ border: "2px solid yellowgreen" }}
                        onClick={() => handleFeeModalOpen()}
                      >
                        Change
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Manage the blacklists</div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-white font-bold"
                    >
                      Wallet address
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-white font-bold"
                    >
                      <input
                        type="text"
                        placeholder="Enter the wallet address"
                        className="admin-wallet-address"
                        value={bannedAddress}
                        onChange={handleBannedAddressChange}
                      />
                    </TableCell>
                    <TableCell align="right" className="text-white">
                      <Button
                        variant="outlined"
                        className="text-white font-bold"
                        style={{
                          border: "2px solid lightskyblue",
                          marginRight: "20px",
                        }}
                        onClick={() => handleBannedAddressList(true)}
                        disabled={!bannedAddress}
                      >
                        Ban
                      </Button>
                      <Button
                        variant="outlined"
                        className="text-white font-bold"
                        style={{ border: "2px solid yellowgreen" }}
                        onClick={() => handleBannedAddressList(false)}
                        disabled={!bannedAddress}
                      >
                        Release
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Modal
        open={feeModalOpen}
        onClose={handleFeeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2>Input the new transaction fee</h2>
          <input
            type="text"
            placeholder="Enter the transaction fee"
            className="admin-wallet-address"
            value={newFee}
            onChange={handleNewFeeChange}
          />
          <Button
            variant="contained"
            className="text-white"
            style={{ marginTop: "20px", width: "100%" }}
            onClick={() => handleChangeFee()}
            disabled={!newFee}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </Layout>
  );
}
