import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import toast, { Toaster } from "react-hot-toast";
import { injected } from "../utils/connector";
require("dotenv").config();

export default function NavBar() {
  const { chainId, active, activate, deactivate, account } = useWeb3React();
  console.log(chainId, active, account);

  useEffect(() => {
    if (active) {
      if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        toast.error(
          "You are on wrong network. Please switch to Ethereum Mainnet to continue"
        );
      }
    }
  }, [chainId, active]);

  async function connect(injected) {
    if (!window.ethereum) {
      toast.error("Please install wallet on your browser");
      return;
    }
    activate(injected);
  }

  async function disConnect(injected) {
    deactivate(injected);
  }

  const renderButton = (
    <>
      {active ? (
        <Stack direction="row" alignItems="center">
          <div className="walletAddress">
            {account.substring(0, 5) + " ... " + account.substring(38)}
          </div>
          <Button
            variant="outlined"
            className="wallet-connect-button text-white"
            onClick={() => disConnect(injected)}
          >
            Disconnect
          </Button>
        </Stack>
      ) : (
        <Button
          variant="outlined"
          className="wallet-connect-button text-white"
          onClick={() => connect(injected)}
        >
          Connect your wallet
        </Button>
      )}
    </>
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      className="main-theme-color"
    >
      <Link href="#" className="text-decoration-none text-white">
        <img src="/logo.png" alt="Logo" className="width-75" />
      </Link>
      <Link href="/transactions" className="text-decoration-none text-white">
        <h2>Transactions</h2>
      </Link>
      <Link href="/appeals" className="text-decoration-none text-white">
        <h2>Appeals</h2>
      </Link>
      <Link href="/admins" className="text-decoration-none text-white">
        <h2>Administrators</h2>
      </Link>
      <Link href="/others" className="text-decoration-none text-white">
        <h2>Others</h2>
      </Link>
      <div>{renderButton}</div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "white",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
          error: {
            style: {
              background: "white",
              color: "black",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
        }}
      />
    </Stack>
  );
}
