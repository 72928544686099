import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import nftContractAbi from "../Abis/escrow-abi.json";
import Layout from "./Layout";
import NavBar from "./NavBar";
import {
  getAdminLists,
  addNewAdmin,
  removeAdmin,
  getHumanReadableMsg,
} from "../utils/utils";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Admins() {
  const { chainId, active } = useWeb3React();
  const validNetwork =
    chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;
  const [isWalletIntalled, setIsWalletInstalled] = useState(false);
  const [adminLists, setAdminLists] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [adminWalletAddressValue, setAdminWalletAddressValue] = useState("");

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  let myProvider, EscrowContract;
  if (window.ethereum) {
    myProvider = new ethers.providers.Web3Provider(window.ethereum);
    EscrowContract = new ethers.Contract(
      process.env.REACT_APP_ESCROW_ADDRESS,
      nftContractAbi,
      myProvider.getSigner()
    );
  }
  useEffect(() => {
    if (window.ethereum) {
      setIsWalletInstalled(true);
    }
  }, []);

  useEffect(() => {
    if (validNetwork && active && window.ethereum) {
      const myProvider = new ethers.providers.Web3Provider(window.ethereum);
      EscrowContract = new ethers.Contract(
        process.env.REACT_APP_ESCROW_ADDRESS,
        nftContractAbi,
        myProvider.getSigner()
      );
      updateAdmins();
    }
  }, [validNetwork, active]);

  const updateAdmins = async () => {
    getAdminLists().then((myAdminLists) => {
      console.log(myAdminLists);
      let newArr = [];
      myAdminLists.map((adminRow) => {
        console.log(adminRow);
        newArr.push({
          walletAddress: adminRow.address,
        });
      });
      setAdminLists(newArr);
    });
  };

  const handleRemoveAdmin = async (walletAddress) => {
    await EscrowContract.removeManagers(walletAddress)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            removeAdmin(walletAddress);

            setTimeout(() => {
              updateAdmins();
            }, 1000);
            return true;
          },
          (error) => {
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
          console.log("========", error);
        }
      });
  };

  const handleAddAdmin = async () => {
    const isValid = validateInputs();
    if (!isValid) return;

    await EscrowContract.addManagers(adminWalletAddressValue)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            addNewAdmin(adminWalletAddressValue);
            setTimeout(() => {
              updateAdmins();
            }, 1000);

            return true;
          },
          (error) => {
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
          console.log("========", error);
        }
      });
  };

  const handleWalletAddressChange = (event) => {
    setAdminWalletAddressValue(event.target.value);
  };

  const validateInputs = () => {
    let isValid = true;

    // validate seller's wallet address
    if (!ethers.utils.isAddress(adminWalletAddressValue)) {
      isValid = false;
      toast.error("The wallet address is not valid");
      return isValid;
    }
    return isValid;
  };

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={8}
        className="margin-top-50"
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 256,
              height: 128,
            },
          }}
        >
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/bitcoin.svg"
                alt="Total Volume Traded"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Volume Traded</div>
                <div className="dashboard-overview-highlight">
                  3,000,000 USD
                </div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/woman.png"
                alt="Total Participants"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Participants</div>
                <div className="dashboard-overview-highlight">5,000</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/rating-star.jpg"
                alt="Success Rating"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Success Rating</div>
                <div className="dashboard-overview-highlight">96.5%</div>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold justify-content-space-between">
          <span>Manage Administrators</span>
          <Button
            variant="contained"
            className="text-white"
            style={{ margin: "10px", padding: "0 38px" }}
            onClick={() => handleModalOpen()}
          >
            Add
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Wallet address
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  adminLists.length > 0 ? (
                    adminLists.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.walletAddress}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Button
                            variant="outlined"
                            className="text-white"
                            style={{ border: "2px solid yellowgreen" }}
                            onClick={() => handleRemoveAdmin(row.walletAddress)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={8}
                      >
                        No admins yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2>Add admin's wallet address</h2>
          <input
            type="text"
            placeholder="Enter wallet address"
            className="admin-wallet-address"
            value={adminWalletAddressValue}
            onChange={handleWalletAddressChange}
          />
          <Button
            variant="contained"
            className="text-white"
            style={{ marginTop: "20px", width: "100%" }}
            onClick={() => handleAddAdmin()}
            disabled={!adminWalletAddressValue}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </Layout>
  );
}
