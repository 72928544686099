import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import nftContractAbi from "../Abis/escrow-abi.json";
import Layout from "./Layout";
import NavBar from "./NavBar";
import {
  getHumanReadableMsg,
  convertTimestampToDateTime,
  formatShortAddress,
  sendEmail,
} from "../utils/utils";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function valuetext(value) {
  return `${value}`;
}

export default function Appeals() {
  const { chainId, active } = useWeb3React();
  const validNetwork =
    chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;
  const [open, setOpen] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [isWalletIntalled, setIsWalletInstalled] = useState(false);
  const [productTrades, setProductTrades] = useState([]);
  const [serviceTrades, setServiceTrades] = useState([]);

  let myProvider, EscrowContract;
  if (window.ethereum) {
    myProvider = new ethers.providers.Web3Provider(window.ethereum);
    EscrowContract = new ethers.Contract(
      process.env.REACT_APP_ESCROW_ADDRESS,
      nftContractAbi,
      myProvider.getSigner()
    );
  }
  useEffect(() => {
    if (window.ethereum) {
      setIsWalletInstalled(true);
    }
  }, []);

  useEffect(() => {
    if (validNetwork && active && window.ethereum) {
      const myProvider = new ethers.providers.Web3Provider(window.ethereum);
      EscrowContract = new ethers.Contract(
        process.env.REACT_APP_ESCROW_ADDRESS,
        nftContractAbi,
        myProvider.getSigner()
      );
      updateProductList().catch(console.error);
      updateServiceList().catch(console.error);
    }
  }, [validNetwork, active]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openResolveModal = (productIndex, category) => {
    setSelectedProductIndex(productIndex);
    setSelectedCategoryIndex(category);
    handleOpen();
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const updateProductList = async () => {
    let newArr = [];
    const productTradeCount = await EscrowContract.currentProductTradeId();
    for (let i = 1; i <= productTradeCount; i++) {
      const productTrade = await EscrowContract.productTrades(i);

      if (!productTrade.appeal) continue;
      newArr.push({
        productId: i,
        createTime: convertTimestampToDateTime(productTrade.createTime),
        buyer: formatShortAddress(productTrade.buyer),
        seller: formatShortAddress(productTrade.seller),
        chatRoomNumber: productTrade.chatRoomNumber,
      });
    }
    setProductTrades(newArr);
  };

  const updateServiceList = async () => {
    let newArr = [];
    const serviceTradeCount = await EscrowContract.currentServiceTradeId();
    for (let i = 1; i <= serviceTradeCount; i++) {
      const serviceTrade = await EscrowContract.serviceTrades(i);
      if (!serviceTrade.appeal) continue;
      newArr.push({
        serviceId: i,
        createTime: convertTimestampToDateTime(serviceTrade.createTime),
        buyer: formatShortAddress(serviceTrade.buyer),
        seller: formatShortAddress(serviceTrade.seller),
        chatRoomNumber: serviceTrade.chatRoomNumber,
      });
    }
    setServiceTrades(newArr);
  };

  const handleResolveAppeal = async () => {
    await EscrowContract.resolveAppeal(
      selectedProductIndex,
      sliderValue === 0,
      selectedCategoryIndex
    )
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
          console.log("========", error);
        }
      });
  };

  if (window.ethereum) {
    myProvider.once("block", () => {
      EscrowContract.on("AppealRequested", (tradeId, category) => {
        console.log(tradeId, category, category.toNumber());
        sendEmail();
      });
    });
  }

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={8}
        className="margin-top-50"
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 256,
              height: 128,
            },
          }}
        >
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/bitcoin.svg"
                alt="Total Volume Traded"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Volume Traded</div>
                <div className="dashboard-overview-highlight">
                  3,000,000 USD
                </div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/woman.png"
                alt="Total Participants"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Total Participants</div>
                <div className="dashboard-overview-highlight">5,000</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <img
                src="/images/rating-star.jpg"
                alt="Success Rating"
                className="width-75"
              />
              <Stack alignItems="center" spacing={2}>
                <div>Success Rating</div>
                <div className="dashboard-overview-highlight">96.5%</div>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Appeals on Product</div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Date/Time
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Chat
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Resolve
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  productTrades.length > 0 ? (
                    productTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Product
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <a
                            href={"/chat/" + row.chatRoomNumber}
                            className="text-white"
                            target="_blank"
                          >
                            View Chat History
                          </a>
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Button
                            variant="outlined"
                            onClick={() => openResolveModal(row.productId, 0)}
                            className="text-white"
                          >
                            Resolve
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={8}
                      >
                        No appeals yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Appeals on Service</div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="main-theme-color"
          >
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">
                  Date/Time
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Chat
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Resolve
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isWalletIntalled ? (
                active ? (
                  serviceTrades.length > 0 ? (
                    serviceTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-white"
                        >
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Service
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <a
                            href={"/chat/" + row.chatRoomNumber}
                            className="text-white"
                            target="_blank"
                          >
                            View Chat History
                          </a>
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Button
                            variant="outlined"
                            onClick={() => openResolveModal(row.serviceId, 1)}
                            className="text-white"
                          >
                            Resolve
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell
                        align="center"
                        className="text-white font-bold"
                        colSpan={8}
                      >
                        No appeals yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell
                      align="center"
                      className="text-white font-bold"
                      colSpan={8}
                    >
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow className="text-white dashboard-overview-highlight">
                  <TableCell
                    align="center"
                    className="text-white font-bold"
                    colSpan={8}
                  >
                    Please install wallet on your browser
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2>Choose Winner</h2>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <p>Buyer</p>
            <Box sx={{ width: 100 }}>
              <Slider
                aria-label="Winner"
                defaultValue={0}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={100}
                marks
                min={0}
                max={100}
                color="secondary"
                onChange={handleSliderChange}
              />
            </Box>
            <p>Seller</p>
          </Stack>
          <Stack
            flexDirection="revert"
            justifyContent="end"
            className="margin-top-50"
          >
            <Button variant="contained" onClick={() => handleResolveAppeal()}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Layout>
  );
}
