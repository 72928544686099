import emailjs from "@emailjs/browser";
import axios from "axios";

const API_SERVER = process.env.REACT_APP_SERVER_ADDRESS
const getChatHistoryURL = API_SERVER + "/getChatHistory";
const getAdminListsURL = API_SERVER + "/getAdminLists";
const addNewAdminURL = API_SERVER + "/addNewAdmin";
const removeAdminURL = API_SERVER + "/removeAdmin";

export function convertTimestampToDateTime(timestamp) {
  const d = new Date(timestamp * 1000);
  let s = d.toUTCString();
  s = s.substring(0, s.indexOf("GMT")) + "UTC";
  return s;
}

export function convertProductStateToStr(stateUint) {
  let stateStr = "";
  switch (stateUint) {
    case 0:
      stateStr = "AWAITING_DELIVERY";
      break;
    case 1:
      stateStr = "AWAITING_FUND_RELEASE";
      break;
    case 2:
      stateStr = "COMPLETE";
      break;
    default:
      break;
  }
  return stateStr;
}

export function convertCryptoStateToStr(stateUint) {
  let stateStr = "";
  switch (stateUint) {
    case true:
      stateStr = "Completed";
      break;
    case false:
      stateStr = "Pending";
      break;
    default:
      break;
  }
  return stateStr;
}

export function findCurrencyType(pairs, currencyAddress) {
  let currencyType = "";
  for (let i = 0; i < pairs.length; i++) {
    if (pairs[i].address === currencyAddress) {
      currencyType = pairs[i].type;
      break;
    }
  }
  return currencyType;
}

export function getHumanReadableMsg(error) {
  let errorMsg = "Only admin can do this operation";
  if (error.message.indexOf("Not enough deposit") > 0)
    errorMsg = "Not enough deposit";
  else if (error.message.indexOf("Lock time is not passed yet") > 0)
    errorMsg = "Lock time is not passed yet.";
  else if (error.message.indexOf("Invalid state") > 0)
    errorMsg = "Invalid state";
  else if (error.message.indexOf("You are not the seller of this trade") > 0)
    errorMsg = "You are not the seller of this trade";
  else if (error.message.indexOf("You are not the buyer of this trade") > 0)
    errorMsg = "You are not the buyer of this trade";
  else if (error.message.indexOf("This trade is not set appeal") > 0)
    errorMsg = "This trade is not set appeal";
  else if (error.message.indexOf("This trade is set appeal") > 0)
    errorMsg = "This trade is set appeal";
  else if (error.message.indexOf("Funds already withdrawn") > 0)
    errorMsg = "Funds already withdrawn";
  else if (error.message.indexOf("Deadline expired") > 0)
    errorMsg = "Deadline expired";
  else if (
    error.message.indexOf("You should wait until the deadline is met") > 0
  )
    errorMsg = "You should wait until the deadline is met";

  return errorMsg;
}

export function formatShortAddress(address) {
  const shortAddr = address.substr(0, 5) + "..." + address.substr(38);
  return shortAddr;
}

export async function loadChatHistory(room_no) {
  const response = await fetch(getChatHistoryURL + "?room_no=" + room_no);
  const chatHistory = response.json();
  return chatHistory;
}

export async function getAdminLists() {
  const response = await fetch(getAdminListsURL);
  const adminLists = response.json();
  return adminLists;
}

export async function addNewAdmin(walletAddress) {
  await axios
    .post(addNewAdminURL, { walletAddress })
    .then((res) => {
      console.log(res.data);
    })
    .catch(function (err) {
      console.log(err, " error");
    });
  return;
}

export async function removeAdmin(walletAddress) {
  await axios
    .post(removeAdminURL, { walletAddress })
    .then((res) => {
      console.log(res.data);
    })
    .catch(function (err) {
      console.log(err, " error");
    });
  return;
}

export function sendEmail() {
  const templateParams = {
    to_name: "Patrick",
    from_name: "Darlene",
    message_html: "New appeal has been added. Please check it.",
  };

  emailjs
    .send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAIL_PUBLIC_KEY
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

export function getAvatarId(participantIds, sender) {
  let avatarId = 0;
  let newParticipantAdded = false;
  if (!participantIds.includes(sender)) {
    avatarId = participantIds.length;
    newParticipantAdded = true;
  } else {
    avatarId = participantIds.indexOf(sender);
  }
  return { avatarId, newParticipantAdded };
}

export function convertIpToHex(ip) {
  const hex =
    ((parseInt(ip.split(/[.]/)[0]) * 256) ^ 0) +
    ((parseInt(ip.split(/[.]/)[1]) * 256) ^ 1) +
    ((parseInt(ip.split(/[.]/)[2]) * 256) ^ 2) +
    ((parseInt(ip.split(/[.]/)[3]) * 256) ^ 3);
  return hex;
}
