import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { loadChatHistory, getAvatarId, convertIpToHex } from "../utils/utils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Chat() {
  const params = useParams();
  const chatId = params?.chatId;

  const [messageHistory, setMessageHistory] = useState([]);
  const participantIds = [];

  useEffect(() => {
    loadChatHistory(chatId).then((chats) => {
      let oldMessages = [];
      for (let i = 0; i < chats.length; i++) {
        const t = chats[i].created_at.split(/[- :T.]/);
        const dateTime = new Date(
          Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
        ).toGMTString();
        const { avatarId, newParticipantAdded } = getAvatarId(
          participantIds,
          chats[i].sender
        );
        if (newParticipantAdded) participantIds.push(chats[i].sender);

        oldMessages.push({
          avatarId,
          name: convertIpToHex(chats[i].sender),
          message: chats[i].message,
          dateTime: dateTime,
        });
      }
      setMessageHistory(oldMessages);
      setTimeout(() => {
        moveScrollBottom();
      }, 500);
    });
  }, []);

  const moveScrollBottom = () => {
    var messageHistoryDiv = document.getElementById("messageHistory");
    messageHistoryDiv.scrollTop = messageHistoryDiv.scrollHeight;
  };

  return (
    <Container maxWidth="lg">
      <Grid container columnSpacing={{ xs: 1 }}>
        <Grid item xs={3} md={3}>
          <Item
            style={{
              height: "100vh",
              backgroundColor: "#120f54",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{ backgroundColor: "darkcyan" }}
            >
              <img
                src="/images/avatar-group.svg"
                alt="avatar"
                className="width-50"
              />
              <Stack>
                <h2 className="text-white margin-left-20">Room #{chatId}</h2>
                <div className="text-white text-left margin-left-20">
                  participants: 0
                </div>
              </Stack>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={9} md={9}>
          <Item
            style={{
              height: "100vh",
              backgroundColor: "#120f54",
              position: "relative",
            }}
          >
            <Stack
              id="messageHistory"
              style={{ overflowY: "auto", height: "88%", margin: "20px" }}
            >
              {messageHistory.length ? (
                messageHistory.map((eachMessage, index) => (
                  <Stack key={index}>
                    <Stack direction="row" alignItems="center">
                      <img
                        src={`/images/avatar${eachMessage.avatarId}.svg`}
                        alt="avatar"
                        className="width-50"
                      />
                      <h2 className="text-white margin-left-20">
                        {eachMessage.name}
                      </h2>
                      <div className="text-white margin-left-20">
                        {eachMessage.dateTime}
                      </div>
                    </Stack>
                    <p className="message-body text-white">
                      {eachMessage.message}
                    </p>
                  </Stack>
                ))
              ) : (
                <h2 className="text-white margin-left-20">
                  Messages will appear here.
                </h2>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="message-textfield"
            >
              <input
                type="text"
                placeholder="Your messages here..."
                className="chat-room-id"
                disabled={true}
              />
              <Button
                variant="contained"
                className="goto-chat-btn"
                disabled={true}
              >
                Send
              </Button>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
}
